@use 'variables';

.component-return {
    margin-top: 20px;
    margin-bottom: 20px;
    @include variables.for-tablet-landscape-up {
        margin: 60px 0px;
    }

    .wrap {
        max-width: 1360px;
        margin: 0 auto;
    }

    h3 {
        font-size: 24px;
        line-height: 27px;
        @include variables.for-tablet-landscape-up {
            font-size: 30px;
            line-height: 40px;
        }
    }

    p {
        font-size: 16px;
        line-height: 27px;
    }

    .button {
        display: flex;
        font-size: 16px;
        @include variables.bold-font;

        a {
            padding: 18px 24px 18px 24px;
            border: 1px solid #e2e2e2;
            color: #000000;
            border-radius: 4px;
            background-color: #ffffff;
            display: inline;
            transition: ease 0.1s;
            @include variables.for-smallUp-only {
                width: 100%;
                text-align: center;
            }
            @include variables.for-mediumUp-only {
                width: auto;
            }
        }

        a:hover {
            background-color: #00A053;
            color: #ffffff;
            border: 1px solid #00A053;
            transition: ease 0.1s;
        }
    }

    .boxes {
        @include variables.for-desktop-up {
            display: flex;
        }
        .return-box {
            height: auto;
            background-color: #000000;
            color: #ffffff;
            border-radius: 10px;
            margin-bottom: 40px;
            box-sizing: border-box;
            padding: 60px 30px 30px 30px;
            @include variables.for-desktop-up {
                flex: 0 1 27%;
            }

            @include variables.for-large-desktop-up {
                flex: 0 1 23%;
            }

            .button {
                padding-top: 25px;
                a {
                    max-width: 250px;
                }
            }
        }

        .info {
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            padding-bottom: 20px;
            @include variables.for-desktop-up {
                justify-content: space-between;
                flex-wrap: wrap;
                flex: 1 1 70%;
            }

            &::-webkit-scrollbar {
                display: none;
            }

            .info-box {
                min-width: 270px;
                height: auto;
                border-radius: 10px;
                border: 1px solid #e2e2e2;
                text-align: center;
                box-sizing: border-box;
                padding: 40px 20px 20px 20px;
                margin-bottom: 20px;
                margin-right: 15px;

                .logo-container {
                    width: 55px;
                    height: 55px;
                    margin: 0 auto;

                    .info-box-logo {
                        width: 100%;
                        height: 55px;
                        object-fit: contain;
                    }
                }

                .info-box-title {
                    margin-top: 20px;
                    margin-bottom: 10px;
                }

                @include variables.for-mediumUp-only {
                    min-width: 330px;
                }

                @include variables.for-desktop-up {
                    min-width: 0;
                    flex: 1 0 250px;
                    height: 260px;
                    margin-left: 20px;
                    margin-right: 0px;
                }

                @include variables.for-large-desktop-up {
                    width: 330px;
                }
            }
        }
    }

    .tabs {
        .tabs-menu {
            @include variables.for-mediumUp-only {
                border: 1px solid #e2e2e2;
                border-radius: 10px;
                box-sizing: border-box;
                padding: 50px;
            }
            @include variables.for-tablet-landscape-up {
                display: flex;
            }

            .tabs-list {
                @include variables.for-mediumUp-only {
                    display: flex;
                    justify-content: space-evenly;

                    .react-tabs__tab {
                        flex: 0 0 26%;
                        max-width: 265px;
                        transition: ease 0.1s;

                        &:hover {
                            cursor: pointer;
                            background-color: #e2e2e2;
                            border-radius: 10px;
                            transition: ease 0.1s;
                        }
                    }
                }

                .react-tabs__tab--selected {
                    background-color: #e2e2e2;
                    border-radius: 10px;
                }

                @include variables.for-tablet-landscape-up {
                    display: block;
                    flex: 0 0 26%;
                    max-width: 300px;
                    padding-bottom: 50px;
                    padding-left: 30px;
                }

                .tab {
                    border: 1px solid #e2e2e2;
                    border-radius: 10px;
                    margin-bottom: 20px;
                    @include variables.for-mediumUp-only {
                        margin-bottom: 0px;
                    }
                    @include variables.for-tablet-landscape-up {
                        margin-bottom: 20px;
                    }

                    .tab-img {
                        margin: 20px 0px 20px 0px;
                        height: 47px;
                    }
                }
            }
        }
        .tab-content {
            padding-top: 50px;
            @include variables.for-tablet-landscape-up {
                padding-top: 0px;
                padding-left: 50px;
                flex: 0 1 70%;
            }

            h3 {
                padding-top: 10px;
                padding-bottom: 10px;
                text-align: center;
                @include variables.for-mediumUp-only {
                    text-align: left;
                }
            }

            ul {
                margin-top: 10px;
            }

            li {
                font-size: 16px;
                margin-left: 15px;
                list-style: none;
                line-height: 1.688rem;
                margin-bottom: 0.625rem;
                padding-left: 30px;
                position: relative;
                @include variables.regular-font;
            }

            li:before {
                position: absolute;
                background-color: #000;
                border-radius: 2px;
                border: 2px solid #000;
                content: '';
                display: inline-block;
                margin-right: 15px;
                vertical-align: middle;
                left: 0;
                top: 10px;
                padding-left: 17px;
            }

            a {
                color: #00A053;
            }
        }
    }
}
